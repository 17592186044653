import type {RecordSourceOptions, DataObject } from 'o365-dataobject';
import { context } from 'o365-modules';
import {filterItemsToString} from './helpers.filterUtils.ts';

class DistinctHandler{
    _viewName:string|null = null;
    _prevWhereClause:string|null = null;
    _prevMasterDetailString:string|null = null;
    _sortOnCount = true;
    _defaultSort = null;
    column = null;
    targetColumn = null;
    existsBinding:string|null = null;
    countField = null;
    distinctColumn = null;
    distinctTargetColumn = null;
    whereClause = null;
    search:string|null = null;
    filterString:string|null = null;
    data:Array<any>;
    dataLoaded: boolean = false;
    definitionProc?: string | null;
    private _existsObject: any = null;
    private _prevContextId: number | null = null;

    dataObject: DataObject | null;
    _dataLoadedDate: Date | null = null;


    sortOnCountDirection: 'asc' | 'desc' = "desc";
    sortAlphabeticallyDirection: 'asc' | 'desc' = "asc";
    get viewName() {
        return this._viewName;
    }

    set viewName(pViewName){
        this._viewName = pViewName;
    }

    get existsClause(){
        if(!this.existsBinding) return null;
        if(this.whereClause){
            return `${this.viewName},${this.existsBinding.replace("T1.","T1_.").replace("T2.","T1.").replace("T1_.","T2.")},${this.whereClause}`;
        }
        return `${this.viewName},${this.existsBinding.replace("T1.","T1_.").replace("T2.","T1.").replace("T1_.","T2.")}`;
    }

     get existsObject(){
        return {
            binding:this.existsBinding?this.existsBinding.replace("T1.","T1_.").replace("T2.","T1.").replace("T1_.","T2."):null,
            viewName:this.viewName,
            distinctColumn:this.distinctColumn,
            distinctTargetColumn:this.distinctTargetColumn??this.distinctColumn,
            whereClause:this.whereClause
        }
    }
  
    set sortOnCount(sortOnCount){
        this._sortOnCount = sortOnCount;
  
    }

    get sortDirection(){
        if(this.sortOnCount){
            return this.sortOnCountDirection;
        }

        return this.sortAlphabeticallyDirection;
    }


    get sortOnCount(){
        return  this._sortOnCount;
    }
    get exist(){
        return this._existsObject;
    }
    constructor(options:any, pExistsObject:any = null){
        this.data = [];
        this.dataLoaded = false;
        this.dataObject = null;
        this._existsObject = pExistsObject;
        if(pExistsObject){
            this.distinctTargetColumn = this._existsObject.column;
            this.distinctColumn = this._existsObject.display;
        }

        this.filterString = null;

        this.column = options.column;
        this.targetColumn = options.targetColumn;
        
        if(options.hasOwnProperty("viewName")){
            this._viewName = options["viewName"];
        }

        if (options.definitionProc !== undefined) {
            this.definitionProc = options.definitionProc;
        }

        this.search = null;
    }
    //return will indicate if there were some changes
    setSortOnCount(sortOnCount:boolean){
     

        if(this.sortOnCount !== sortOnCount){
            //sort direction not changing only which one with previuos sorting
           this.sortOnCount = sortOnCount;
           return true;
        }else{
            if(sortOnCount){
                this.sortOnCountDirection = this.sortOnCountDirection==='asc'?'desc':'asc';
                return true;
            }else{
                this.sortAlphabeticallyDirection = this.sortAlphabeticallyDirection==='asc'?'desc':'asc';
                return true;
            }
        }
       
    }
    private _getQueryOptions() {
        if (this.dataObject == null) { return null; }
        const options: Partial<RecordSourceOptions> = {
            fields: []
        };

        const vFilterObj = this.changeFilterItems(([this.distinctTargetColumn, this.targetColumn, this.column]), this.dataObject.recordSource.getItemsClone());
        const vFilterString = vFilterObj ? filterItemsToString(vFilterObj)?.replace('[CLEARED_DISTINCT_PLACEHOLDER]', '1')?.replace('CLEARED_DISTINCT_PLACEHOLDER', '1') : null;

        options["filterString"] = vFilterString;
        options["filterObject"] = vFilterObj;
        options.expandView = this.dataObject.recordSource.expandView;
        options.definitionProc = this.dataObject.recordSource.definitionProc;

        if (options.expandView || this.dataObject.recordSource.definitionProc || this.dataObject.recordSource.contextId) {
            options.contextId = this._getContextId();
        }
        if (this.dataObject.recordSource.sqlStatementParameters) {
            options.sqlStatementParameters = { ...this.dataObject.recordSource.sqlStatementParameters };
        }
        if (this.dataObject.recordSource.definitionProcParameters) {
            options.definitionProcParameters = { ...this.dataObject.recordSource.definitionProcParameters };
        }

        let vCurrentWhereClause = this.dataObject.recordSource.getWhereClause();
        options["whereClause"] = vCurrentWhereClause;
        this._prevWhereClause = vCurrentWhereClause;

        options["maxRecords"] = -1;
        options["viewName"] = this._viewName!;
        options["loadRecents"] = false;
        // options["distinctRows"] = this.dataObject.recordSource.distinctRows;

        let useDistinctDefinitionProc = false;
        if (this.definitionProc) {
            options.definitionProc = this.definitionProc;
            if (options.sqlStatementParameters == null) { options.sqlStatementParameters = {}; }
            options.sqlStatementParameters[this.distinctColumn ?? this.column!] = this.search ? `%${this.search}%` : null;
            useDistinctDefinitionProc = true;
        } else if (this.definitionProc === null) {
            delete options.definitionProc;
        }

        if (!useDistinctDefinitionProc && (this.existsBinding || this._existsObject)) {
            this.sortOnCount = false;
        }

        if (this.dataObject.masterDetails) {
            options["masterDetailString"] = this.dataObject.masterDetails.getFilterString()!;
            this._prevMasterDetailString = options['masterDetailString'];
        }
        if (options["masterDetailString"] && this.dataObject.masterDetails) {
            options["masterDetailObject"] = this.dataObject.masterDetails.getFilterObject();
        }


        if (this._existsObject) {
            options["viewName"] = this._existsObject.viewName;
            options.fields!.push({
                name: this._existsObject.column,
                sortDirection: this.sortDirection,
                sortOrder: this.sortOnCount ? undefined : 2,
                groupByOrder: 1
            });
            if (this._existsObject.display && this._existsObject.column != this._existsObject.display) {
                options.fields!.at(-1)!.sortOrder = undefined;
                options.fields!.at(-1)!.sortDirection = undefined;
                options.fields!.push({
                    name: this._existsObject.display,
                    sortDirection: this.sortDirection,
                    sortOrder: this.sortOnCount ? undefined : 1,
                    groupByOrder: 1
                });
            }
            options.fields!.push({
                name: this._existsObject.column,
                alias: "Count",
                aggregate: this.dataObject.recordSource.distinctRows ? "COUNT_DISTINCT" : "COUNT",
                sortDirection: this.sortOnCount ? this.sortDirection : undefined,
                sortOrder: this.sortOnCount ? 1 : undefined
            })

            const vWhere = [];
            if (options.whereClause) {
                vWhere.push("(" + options.whereClause + ")")
                options.whereClause = undefined;
            }

            if (options.filterString) {
                vWhere.push("(" + options.filterString + ")");
                options.filterString = undefined;
            }

            if (this.search && !useDistinctDefinitionProc) {
                vWhere.push("(" + `T1.${this._existsObject.column} LIKE '%${this.search}%'` + ")");
            }

            if (useDistinctDefinitionProc) {
                if (vWhere.length) {
                    options.whereClause = vWhere.join(' AND ');
                }
            } else if (vWhere.length) {
                options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this._existsObject.bindingFordistinct}, ${vWhere.join(" AND ")})`;
            } else {
                options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this._existsObject.bindingFordistinct})`;
            }

            return options;
        }

        const vCountField = this.getCountField();

        if (this.distinctTargetColumn) {
            options["fields"]?.push({
                name: this.distinctTargetColumn,
                sortDirection: this.sortDirection,
                sortOrder: 2,
                groupByOrder: 1
            })
        }

        if (this.targetColumn) {
            options["fields"]?.push({
                name: this.targetColumn,
                sortDirection: this.sortOnCount ? null : this.sortDirection,
                sortOrder: this.sortOnCount ? null : 2,
                groupByOrder: 1
            })
        }

        if (!options["fields"]?.find(x => x.name == this.distinctColumn ?? this.column)) {
            options["fields"]?.push({
                name: this.distinctColumn ?? this.column,
                sortDirection: this.sortOnCount ? null : this.sortDirection,
                sortOrder: this.sortOnCount ? null : 1,
                groupByOrder: 1
            })
        }

        options.fields?.push({
            name: vCountField,
            alias: "Count",
            aggregate: this.dataObject.recordSource.distinctRows ? "COUNT_DISTINCT" : "COUNT",
            sortDirection: this.sortOnCount ? this.sortDirection : null,
            sortOrder: this.sortOnCount ? 1 : null
        });

        if(this.search && !this.existsBinding && !useDistinctDefinitionProc){
            options["whereObject"] = {
                column:(this.column),
                operator:"contains",
                value:this.search,
                type:"expression"
            }
            if(vCurrentWhereClause){
                options["whereClause"] = vCurrentWhereClause + " AND ["+(this.column) +"] LIKE '%"+this.search+"%'";
            }else{
                options["whereClause"] = "["+(this.column) +"] LIKE '%"+this.search+"%'";
            }
            
        }else{
            options["whereClause"] = vCurrentWhereClause;
        }

        if(this.existsBinding){
            const vWhere = [];
            if(options.whereClause){
               vWhere.push("("+options.whereClause+")") 
               options.whereClause = null;
            }

            if(options.filterString){
                vWhere.push("("+options.filterString+")");
                options.filterString = null;
            }
            if(vWhere.length){
                if(this.search){
                    const searchStatement = `T1.${this.distinctColumn} LIKE '%${this.search}%'`;
                   options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this.existsBinding}, ${[...vWhere, searchStatement].join(" AND ")})`; 
                }else{
                    options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this.existsBinding}, ${vWhere.join(" AND ")})`;
                }
               
            }else{
                if(this.search){
                    options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this.existsBinding} AND T2.${this.distinctColumn} LIKE '%${this.search}%')`; 
                }else{
                    options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this.existsBinding})`;
                }
               
            }
            options.filterString = "1=1"
        }
        
     
        return options;

    }

  

    getData(reload: boolean){
        if (this.dataObject == null) { return; }

        const vFilterObj = this.changeFilterItems(([this.distinctTargetColumn,this.targetColumn,this.column]), this.dataObject.recordSource.getItemsClone());
        const vFilterString = vFilterObj ? filterItemsToString(vFilterObj)?.replace('[CLEARED_DISTINCT_PLACEHOLDER]', '1')?.replace('CLEARED_DISTINCT_PLACEHOLDER', '1') : null;

        if(this._prevWhereClause != this.dataObject.recordSource.getWhereClause()){
            reload = true;
        }

        if (!reload && this.dataObject.masterDetails && this._prevMasterDetailString !=this.dataObject.masterDetails.getFilterString()) {
            reload = true;
        }

        if(this._dataLoadedDate && this.dataObject.state.changedDate && this._dataLoadedDate < this.dataObject.state.changedDate){
            reload = true;
        }
        
        if ((this.dataObject.recordSource.expandView || this.dataObject.recordSource.definitionProc || this.dataObject.recordSource.contextId) && this._prevContextId != this._getContextId()) {
            reload = true;
        }

        if(this.dataLoaded && this.filterString == vFilterString && !reload){
            return Promise.resolve(this.data);
        }

        this.filterString = vFilterString;
     
        // if(this.existsBinding || this._existsObject){
        //     this.sortOnCount = false;
        // }

        const options = this._getQueryOptions();

        if (!(options.includeFilterObjects || this.dataObject.clientSideFiltering)) {
            // deleting cause its being sent to the back otherwise and fails
            delete options.filterObject;
            delete options.whereObject;
            delete options.masterDetailObject;
        }

        this._prevContextId = options?.contextId ?? null;
        
        return new Promise((resolve, reject)=>{
            
            this.dataObject.dataHandler.distinct(options).then((pData:Array<any>)=>{
                this.dataLoaded = true;
                this.data = pData;
                this._dataLoadedDate = new Date();
                resolve(pData);
            }).catch(ex => {
                reject(ex);
            }); 
        });
        
    }

    getCountField(){
        if(this.countField){
            return this.countField;
        }
        if(this.dataObject.fields.uniqueField){
            return this.dataObject.fields.uniqueField
        }

        return this.targetColumn?? this.column;
       
      
    }

    setDataObject(pDataObjectId){
        if(typeof pDataObjectId === 'string'){
            this.dataObject = o365.model.dataObjects[pDataObjectId];
        }else{
            this.dataObject = pDataObjectId;
        }

        if(!this._viewName){
            this._viewName = this.dataObject.viewName;
        }
        
    }

    changeFilterItems = (pKey, pObj)=>{
        if (!pObj) return null;
        
        if(pObj.length){
             for(var i = 0; i< pObj.length; i++){
                 if(pKey.indexOf(pObj[i].column)>-1){
                     pObj[i].column = 'CLEARED_DISTINCT_PLACEHOLDER';
                     pObj[i].value = '1';
                     pObj[i].operator = 'equals';

                     delete pObj[i].useExist;
                     delete pObj[i].existsObject;
                     delete pObj[i].exists;

                    // delete pObj[i];
                    // pObj.length -=1;
                   
                   
                   // return;
                }
                this.changeFilterItems(pKey, pObj[i]); 
             }
        }
        
        if(pObj.items ){
            this.changeFilterItems(pKey, pObj.items);
        }
    
        return pObj;
    }

    private _getContextId() {
        return this.dataObject?.recordSource.contextId === undefined ? context.id : this.dataObject.recordSource.contextId;
    }
}

export {DistinctHandler}; 
